<template>
  <template v-for="item in detailData" :key="item.id">
    <div class="detailData">
      <ul>
        <li>트랜젝션키</li>
        <li>{{ item["transactionKey"] }}</li>
      </ul>
      <template v-if="item.detail">
        <ul>
          <li>게임ID</li>
          <li>{{ item.detail.id }}</li>
        </ul>
        <ul>
          <li>제공 게임사</li>
          <li>{{ item.detail.gameProvider }}</li>
        </ul>
        <ul>
          <li>게임타입</li>
          <li>{{ item.detail.gameType }}</li>
        </ul>
        <template v-for="(uValue, idx) in item.detail['participants']">
          <ul>
            <li>유저아이디 (게임내ID)</li>
            <li>{{ item.detail.participants[idx].screenName }}</li>
          </ul>
          <ul>
            <li>게임ID</li>
            <li>{{ item.detail.participants[idx].playerGameId }}</li>
          </ul>
          <template v-for="(xValue, i) in uValue['bets']">
            <ul>
              <li>베팅위치</li>
              <li>{{ item.detail.participants[idx].bets[i].code }}</li>
            </ul>
            <ul>
              <li>{{ $t('table.head.betAmt') }}</li>
              <li>{{ item.detail.participants[idx].bets[i].stake }}</li>
            </ul>
            <ul>
              <li>{{ $t('table.head.winAmt') }}</li>
              <li>{{ item.detail.participants[idx].bets[i].payout }}</li>
            </ul>
            <ul>
              <li>베팅시각</li>
              <li>{{ item.detail.participants[idx].bets[i].placedOn }}</li>
            </ul>
            <ul>
              <li>트랜젝션아이디</li>
              <li>{{ item.detail.participants[idx].bets[i].transactionId }}</li>
            </ul>
            <p class="dataname">베팅 및 게임결과 : {{ item.detail.gameType }}</p>
            <ul v-if="item.detail.result.banker">
              <li v-if="item.detail.result.banker && item.detail.result.banker.score">뱅커 : {{ item.detail.result.banker.score}}</li>
              <li class="data">
                <div class="card">
                  <em :class="'C' + item.detail.result.banker.cards[0]">{{ item.detail.result.banker.cards[0] }}</em>
                  <em :class="'C' + item.detail.result.banker.cards[1]">{{ item.detail.result.banker.cards[1] }}</em>
                  <em :class="'C' + item.detail.result.banker.cards[2]">{{ item.detail.result.banker.cards[3] }}</em>
                </div>
              </li>
            </ul>
            <ul v-if="item.detail.result.player">
              <li>플레이어 : {{ item.detail.result.player.score}}</li>
              <li class="data">
                <div class="card">
                  <em :class="'C' + item.detail.result.player.cards[0]">{{ item.detail.result.player.cards[0] }}</em>
                  <em :class="'C' + item.detail.result.player.cards[1]">{{ item.detail.result.player.cards[1] }}</em>
                  <em :class="'C' + item.detail.result.player.cards[2]">{{ item.detail.result.player.cards[3] }}</em>
                </div>
              </li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.outcome">
              <li>결과</li>
              <li>"{{ item.detail.result.outcome }}"</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.buttonsCount">
              <li>buttonsCount</li>
              <li>{{ item.detail.result.buttonsCount }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.luckyNumbers">
              <li>luckyNumbers</li>
              <li>{{ item.detail.result.luckyNumbers }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.payoutLevel">
              <li>payoutLevel</li>
              <li>{{ item.detail.result.payoutLevel }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.reachedMultiplier">
              <li>reachedMultiplier</li>
              <li>{{ item.detail.result.reachedMultiplier }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.redEnvelopePayouts">
              <li>redEnvelopePayouts</li>
              <li>{{ item.detail.result.redEnvelopePayouts }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.winningNumbers">
              <li>winningNumbers</li>
              <li>{{ item.detail.result.winningNumbers }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.first">
              <li>first</li>
              <li>{{ item.detail.result.first }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.second">
              <li>second</li>
              <li>{{ item.detail.result.second }}</li>
            </ul>
            <ul v-if="item.detail.result && item.detail.result.third">
              <li>third</li>
              <li>{{ item.detail.result.third }}</li>
            </ul>
          </template>
        </template>
      </template>
      <!--{{item}}-->
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCode1',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
